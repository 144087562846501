/**
 * 要素がふわっと表示されるアニメーション
 */

import ScrollReveal from 'scrollreveal';

class ScrollAnimation {
    constructor() {
        // アニメーション設定
        ScrollReveal().reveal('.effect-fade div', {
            duration: 2000,
            reset: false
        });
    }
}

const scrollAnimation = new ScrollAnimation();
